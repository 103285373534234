import { useTranslation } from "react-i18next";
import LandingSection from "../../../shared/components/landing-section";
import ContactUsPage from "./contact-us";
import LandingHome from "../contact-us-home";

export default function ClientHome() {
    const { t } = useTranslation();

    return (
        <>
            <LandingSection
                id={t("nav.home")}
                style={{
                    width: '100%',
                    backgroundColor: "white"
                }}>
                <LandingHome />
            </LandingSection>

            <LandingSection
                id={t("contactUs")}
                style={{
                    width: '100%',
                    backgroundColor: "#edecff",
                }}>
                <ContactUsPage />
            </LandingSection>

        </>);
}