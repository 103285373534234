import { Box, Fade, Grid, Slide, Typography, debounce } from "@mui/material";
import { useState } from "react";
import person from "../../assets/2.jpg";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Proccess() {

    const [animation, setAnimation] = useState(false);

    const {t} = useTranslation();
    const activeAnimation = debounce(() => {
        if (window.scrollY > 200) {
            setAnimation(true);
        }
    }, 100);

    window.addEventListener('scroll', activeAnimation);

    return (
        <Box alignContent={'center'}>
            {/* Mobile screen*/}
            <Box textAlign={'center'}
                alignContent={'center'}
                sx={{
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        lg: 'none'
                    },
                    height: '90vh'
                }}>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6}>
                        <Slide timeout={1500} direction="right" in={animation}>
                        <Box>
                                <Typography variant="h4" color={'primary'} fontWeight={700}>{t("landing.legallyUs")}</Typography>
                                <div style={{
                                    backgroundColor: theme.palette.primary.main,
                                    width: '100%',
                                    borderRadius: 15,
                                    marginTop: 5,
                                    textAlign: 'center'
                                }}>
                                <Typography variant="h4" color={'white'} fontWeight={700}>{t("landing.helpYou")}</Typography>
                                </div>
                                <ul
                                    style={{
                                        fontSize: 24,
                                        textAlign: 'left',
                                        listStyleType: 'none'
                                    }}>
                                    <li style={{ margin: 12, color: theme.palette.primary.main, fontSize:30, fontWeight:600}}><CheckCircleIcon sx={{color:'green', fontSize:30, marginRight:5}}/>{t('landing.process.identify')}</li>
                                    <li style={{ margin: 12, color: theme.palette.primary.main, fontSize:30, fontWeight:600}}><CheckCircleIcon sx={{color:'green', fontSize:30, marginRight: 5}}/>{t('landing.process.find')}</li>
                                    <li style={{ margin: 12, color: theme.palette.primary.main, fontSize:30, fontWeight:600 }}><CheckCircleIcon sx={{color:'green', fontSize:30, marginRight: 5}}/>{t('landing.process.handle')}</li>
                                </ul>
                            </Box>
                        </Slide>

                    </Grid>
                    <Grid item md={6} lg={6}>
                        {/* <Fade timeout={3000} in={animation}>
                            <img style={{
                                padding: 5,
                                borderRadius: 25,
                                height: '80%',
                                width: '80%'
                            }} src={proccesImage} /></Fade> */}

                    </Grid>
                </Grid>
            </Box>

            {/* Big screen */}
            <Box textAlign='left'
                alignContent={'center'}
                sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                        lg: 'block'
                    },
                    height: '80vh'
                }}>
                <Grid container spacing={2}>
                <Grid item md={6} lg={6}>
                        <Fade timeout={1500} in={animation}>
                            <img style={{
                                padding: 5,
                                borderRadius: 25,
                                height: '100%',
                                width: '100%'
                            }} src={person} /></Fade>

                    </Grid>
                    <Grid item md={6} lg={6}>
                        <Slide timeout={1700} direction="right" in={animation}>
                            <Box>
                                <Typography variant="h4" color={'primary'} fontWeight={700}>{t("landing.legallyUs")}</Typography>
                                <div style={{
                                    backgroundColor: theme.palette.primary.main,
                                    width: '100%',
                                    borderRadius: 15,
                                    marginTop: 5,
                                    textAlign: 'center'
                                }}>
                                <Typography variant="h4" color={'white'} fontWeight={700}>{t("landing.helpYou")}</Typography>
                                </div>
                                <ul
                                    style={{
                                        fontSize: 24,
                                        textAlign: 'left',
                                        listStyleType: 'none'
                                    }}>
                                    <li style={{ margin: 12, color: theme.palette.primary.main, fontSize:30, fontWeight:600}}><CheckCircleIcon sx={{color:'green', fontSize:30, marginRight:5}}/>{t('landing.process.identify')}</li>
                                    <li style={{ margin: 12, color: theme.palette.primary.main, fontSize:30, fontWeight:600}}><CheckCircleIcon sx={{color:'green', fontSize:30, marginRight: 5}}/>{t('landing.process.find')}</li>
                                    <li style={{ margin: 12, color: theme.palette.primary.main, fontSize:30, fontWeight:600 }}><CheckCircleIcon sx={{color:'green', fontSize:30, marginRight: 5}}/>{t('landing.process.handle')}</li>
                                </ul>
                            </Box>
                        </Slide>

                    </Grid>
                </Grid>

            </Box>
        </Box>
    )
}