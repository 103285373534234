import { Container, Divider } from "@mui/material";

export interface LandingSectionProps {
    id: string;
    children?: any;
    style: any;
    backgrounImage?: string;
    height?: number;
    className?: string;
}

export default function LandingSection(props: LandingSectionProps) {
    return (
        <div
            className={props.className}
            id={props.id}
            style={{ ...props.style, backgroundImage: props.backgrounImage }}>
            <Container sx={{
                paddingBottom: 5,
                paddingTop: 5,
                display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block"
                }
            }}>
                {props.children}
            </Container>
            <Container sx={{
                paddingBottom: 5,
                paddingTop: 5,
                height: props.height,
                display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none"
                }
            }}>
                {props.children}
            </Container>
        </div>
    );
}