import { Box, Button, CircularProgress, Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { City, Country, State } from "country-state-city";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clientService from "../../../shared/services/client-service";
import NavBar from "../../../shared/components/nav-bar";
import theme from "../../../theme";

export interface ContactRequest {
    companyName: string;
    companyDescription: string;
    contactName: string;
    phoneNumber: string;
    country: string;
    state: string;
    city: string;
    address: string;
    email: string;
    message?: string;
}

export default function ContactUsPage() {
    const { register, handleSubmit, formState: { errors } } = useForm<ContactRequest>();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<string>('');
    const [selectedState, setSelectedState] = useState<string>('');
    const countries = Country.getAllCountries();
    const [states, setStates] = useState<Array<any>>([]);
    const [cities, setCities] = useState<Array<any>>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setStates(State.getStatesOfCountry(selectedCountry));
    }, [selectedCountry]);

    useEffect(() => {
        setCities(City.getCitiesOfState(selectedCountry, selectedState));
    }, [selectedState]);

    const handleForm: SubmitHandler<any> = (data: ContactRequest) => {
        setLoading(true);
        clientService.contactUs(data)
            .then(resp => {
                setLoading(false);
                navigate("/confirmation");
            }).catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    return (

        <Box
            sx={{ marginTop: 2 }}>
            {loading
                ? <Box justifyContent={'center'} alignContent={'center'} sx={{ display: 'flex', marginTop: 25 }}>
                    <CircularProgress size={100} />
                </Box>
                : <Box
                    sx={{ padding: 2, height: 'auto' }}>
                    <Typography variant="h4" fontWeight={500}>
                        Contact Us
                    </Typography>

                    <form style={{
                        marginTop: 5,
                        paddingTop: 5,
                    }} onSubmit={handleSubmit(handleForm)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t('forms.contact.companyName')}
                                    type="text"
                                    {...register('companyName',
                                        {
                                            required: true,
                                        }
                                    )}
                                    error={errors!.companyName ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t('forms.contact.companyDescription')}
                                    type="text"
                                    {...register('companyDescription',
                                        {
                                            required: true
                                        }
                                    )}
                                    error={errors!.companyDescription ? true : false} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t('forms.contact.contactName')}
                                    type="text"
                                    {...register('contactName',
                                        {
                                            required: true
                                        }
                                    )}
                                    error={errors!.contactName ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t('forms.contact.email')}
                                    type="email"
                                    {...register('email',
                                        {
                                            required: true
                                        }
                                    )}
                                    error={errors!.email ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="country"
                                    label={t('forms.contact.country')}
                                    variant="outlined"
                                    select
                                    {...register('country',
                                        {
                                            required: true,
                                            onChange: (value) => { setSelectedCountry(value.target.value) }
                                        })}
                                    error={errors!.country ? true : false}
                                >
                                    {countries.map(country => {
                                        return (<MenuItem key={country.isoCode} value={country.isoCode}>{country.name}</MenuItem>);
                                    })}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="state"
                                    label={t('forms.contact.state')}
                                    variant="outlined"
                                    select
                                    {...register('state',
                                        {
                                            required: true,
                                            onChange: (value) => { setSelectedState(value.target.value) }
                                        })}
                                    error={errors!.state ? true : false}
                                >
                                    {states.map(state => {
                                        return (<MenuItem key={state.isoCode} value={state.isoCode}>{state.name}</MenuItem>);
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="city"
                                    label={t('forms.contact.city')}
                                    variant="outlined"
                                    select
                                    {...register('city',
                                        {
                                            required: true,
                                        })}
                                    error={errors!.city ? true : false}
                                >
                                    {cities.map(city => {
                                        return (<MenuItem key={city.isoCode} value={city.name}>{city.name}</MenuItem>);
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={2}
                                    id="address"
                                    label={t('forms.contact.address')}
                                    variant="outlined"
                                    {...register('address', { required: true })}

                                    error={errors!.address ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    style={{
                                        width: '95%',
                                        marginTop: 10,
                                        marginBottom: 5,
                                        marginLeft: 20,
                                    }}
                                    numberInputProps={{
                                        style: {
                                            height: 50,
                                            fontSize: 'medium',
                                            backgroundColor: '#edecff',
                                            border: '1px solid rgba(109,109,143, 0.5)',
                                            borderRadius: 2,
                                        }
                                    }}
                                    {...register('phoneNumber', {
                                        required: true,
                                        minLength: { value: 6, message: "Phone number is required." }
                                    })}
                                    error={errors!.phoneNumber ? true : false}
                                    defaultCountry={selectedCountry as any}
                                    placeholder={t('forms.contact.phoneNumber')}
                                    onChange={() => console.log()} />
                                {errors!.phoneNumber?.message
                                    ? <Typography color={'error'}>{errors!.phoneNumber?.message.toString()}</Typography>
                                    : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    rows={2}
                                    id="message"
                                    label={t('forms.contact.message')}
                                    variant="outlined"
                                    {...register('message')}
                                />
                            </Grid>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button
                                    sx={{ margin: 3, backgroundColor: theme.palette.secondary.main }}
                                    type="submit" title={t('forms.contact.submit')} variant="contained">{t('forms.contact.submit')} </Button>
                            </Box>
                        </Grid>
                    </form>
                </Box>
            }
        </Box>)
}