import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import LanguageSelector from "./language-selector";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-blanco.png";
import theme from "../../theme";
import { useTranslation } from "react-i18next";


const drawerWidth = 240;

export default function NavBar(props: { children: any, showLinks: boolean, showIcon?: boolean }) {
    const { t } = useTranslation();
    const [mobileOpen, setMobileOpen] = useState(false);
    const navItems = [
        {
            title: t("nav.home"), route: './'
        },
        // {
        //     title: t("nav.services"), route: `./#${t("nav.services")}`
        // },
        {
            title: t("nav.aboutUs"), route: 'aboutUs'
        },
        // {
        //     title: t("nav.steps"), route: `./#${t("nav.steps")}`
        // },
        {
            title: t("nav.contactUs"), route: `contactUs`
        }];
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle}
            sx={{
                textAlign: 'center', margin: 0, padding: 0,
                backgroundColor: theme.palette.primary.main
            }}>
            <Link to={'/'}>
                <img alt="Laboria logo" style={{
                    marginTop: 8,
                    width: 200,
                    height: 90
                }} src={logo} />
            </Link>
            <Divider />
            {props.showLinks
                ?
                <List>
                    {navItems.map((item) => (
                        <ListItem key={item.title} disablePadding>
                            <ListItemButton sx={{ textAlign: 'center' }} href={`${item.route}`}>
                                <ListItemText primary={item.title} sx={{ color: 'white' }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <Link to={'openings'} style={{
                        textDecoration: 'none',
                        color: theme.palette.text.primary
                    }}>
                    </Link>
                </List>
                : null}
        </Box >
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            sx={{
                display: 'flex',
                margin: 0,
                padding: 0,
            }}>
            <CssBaseline />
            <AppBar component="nav" sx={{
                backgroundColor: 'primary',
                boxShadow: 'none',
            }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        <a href="/">
                            <img hidden={!props.showIcon} alt="Laboria logo" style={{
                                width: 130,
                                height: 65,
                                marginTop: 4

                            }} src={logo} />
                        </a>
                    </Box>


                    {props.showLinks
                        ? <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                            {navItems.map((item) => (
                                <Button key={item.title} href={`${item.route}`} sx={{
                                    color: 'white'
                                }}>
                                    {item.title}
                                </Button>
                            ))}

                            {/* <Link to={'openings'}>
                                <Button sx={{
                                    color: 'white'
                                }}>
                                    {t("nav.openings")}
                                </Button>
                            </Link> */}
                        </Box>
                        : null}
                    <LanguageSelector />
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: theme.palette.primary.main },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main" sx={{ width: '100%', margin: 0, padding: 0 }}>
                {props.children}
            </Box>
        </Box>
    );

}