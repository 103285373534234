import { Box, Card, CardContent, CardHeader, Fade, Grid, Slide, Typography, debounce } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LandingSection from "../../shared/components/landing-section";
import theme from "../../theme";
import "../../shared/css/about.css";

export default function AboutUsPage() {
    const { t } = useTranslation();
    const [animation, setAnimation] = useState(false);

    const activeAnimation = debounce(() => {
        if (window.scrollY > 1) {
            setAnimation(true);
        }
    }, 100);

    window.addEventListener('scroll', activeAnimation);

    return (
        <Fade in timeout={1000}>
            <Box className="aboutGradientBackground">
                <LandingSection
                    id={"description"}
                    style={{
                        width: '100%',
                    }}>
                    <Box marginTop={10}>
                        <Typography variant={"h3"} color={'white'} fontWeight={'bold'}>
                            {t("aboutUs.whatWeDo")}
                        </Typography>
                        <Typography variant={"body1"} color={'white'}>
                            {t("aboutUs.description")}
                        </Typography>
                    </Box>
                </LandingSection>

                <LandingSection
                    id={"steps"}
                    style={{
                        width: '100%'
                    }}>
                    <Typography variant={"h3"} color={'white'} fontWeight={'bold'}>
                        {t("aboutUs.howWeDoIt")}
                    </Typography>
                    <Typography variant={"body1"} color={'white'} marginBottom={2}>
                        {t("aboutUs.howWeDoItDescription")}
                    </Typography>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <Card sx={{
                                backgroundImage: `linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(217, 217, 243, 0.7), rgba(217, 217, 243, 0.9)), url(${require("../../assets/searchAndSelection.png")})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "right",
                                color: 'black',
                                height: 300,
                                width: '100%',
                                fontWeight: '500',
                            }}>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        height: '100%',
                                    }}>
                                    {t("aboutUs.howWeDoitList.step1")}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Card sx={{
                                backgroundImage: `linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(217, 217, 243, 0.7), rgba(217, 217, 243, 0.9)), url(${require("../../assets/passport.png")})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "right",
                                color: 'black',
                                height: 300,
                                width: '100%',
                                fontWeight: '500',
                            }}>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        height: '100%',
                                    }}>
                                    {t("aboutUs.howWeDoitList.step2")}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Card sx={{
                                backgroundImage: `linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(217, 217, 243, 0.7), rgba(217, 217, 243, 0.9)), url(${require("../../assets/support.png")})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "right",
                                backgroundPositionY: "center",
                                color: 'black',
                                height: 300,
                                width: '100%',  
                                fontWeight: '500',
                            }}>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        height: '100%',
                                    }}>
                                    {t("aboutUs.howWeDoitList.step3")}
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Card sx={{
                                backgroundImage: `linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(217, 217, 243, 0.7), rgba(217, 217, 243, 0.9)), url(${require("../../assets/transparencia.png")})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionX: "right",
                                backgroundPositionY: "center",
                                color: 'black',
                                height: 300,
                                width: '100%',
                                fontWeight: '500',
                            }}>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        height: '100%',
                                    }}>
                                    {t("aboutUs.howWeDoitList.step4")}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </LandingSection>
                <LandingSection id={"team"} style={{ width: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <div className="misionVisionBackground">
                                <Typography variant="h4" color={'black'}>
                                    {t("aboutUs.ourMision")}
                                </Typography>
                                <Typography variant="body1" color={'black'}>
                                    {t("aboutUs.mision")}
                                </Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="misionVisionBackground">
                                <Typography variant="h4" color={'black'}>
                                    {t("aboutUs.ourVision")}
                                </Typography>
                                <Typography variant="body1" color={'black'}>
                                    {t("aboutUs.vision")}
                                </Typography> </div>

                        </Grid>
                    </Grid>
                </LandingSection>
            </Box>
        </Fade>
    );
}