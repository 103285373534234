const translation = {
    title: 'Welcome to Laboria!',
    language: 'Language',
    nav: {
        home: 'Home',
        aboutUs: 'About Us',
        services: 'Services',
        contact: 'Contact',
        contactUs: 'Contact Us',
        openings: 'Openings',
        steps: 'Steps'
    },
    landing: {
        applyNow: 'Apply Now',
        contactUs: 'Contact Us',
        showMore: 'Contact Us',
        legallyUs: `Don't you know how work legally in the UNITED STATES?`,
        helpYou: 'We help you!',
        applicantTitle: 'Would you like to apply for a job opportunity?',
        applicantDescription: 'We will help you during the proccess',
        clientTitle: 'Are you looking for top-notch resources for your temporary vacancies?',
        clientDescription: 'Acquire our services.',
        process: {
            identify: 'We identify your skills',
            find: 'We find the best job for you',
            handle: 'We handle the paperwork'
        }
    },
    openings: {
        title: 'Job Openings',
        details: {
            description: "Description",
            location: "Location",
            salary: "Salary",
            apply: "Apply"
        },
        list: {
            position: 'Position',
            field: 'Field',
            description: 'Description',
            location: 'Location',
            options: 'Options'
        }
    },
    steps: {
        personalInfo: 'Personal information',
        dsForm: 'DS-160'
    },
    forms: {
        yes: 'Yes',
        no: 'No',
        personalInfo: {
            idIsNotValid: 'There is an existing application with this Identification number',
            id: 'ID',
            name: 'First Name',
            middleName: 'Middle Name',
            lastName: 'Last Name',
            gender: 'Gender',
            maritalStatus: 'Marital Status',
            partnerName: 'Partner Name',
            haveKids: 'Do you have kids?',
            educationLevel: 'Education Level',
            profession: 'Profession or occupation',
            englishLevel: 'English Level',
            workExperience: 'Work Experience',
            havePassport: 'Do you have a passport?',
            visa: 'Have you received any type of United States visa?',
            country: 'Country',
            state: 'State',
            city: 'City',
            address: 'Address',
            phone: 'Phone number',
            birthDate: 'Birthdate',
            email: 'E-mail',
            next: 'Next'
        },
        genderOptions: {
            male: 'Male',
            female: 'Female',
            other: 'Prefer not to say'
        },
        maritalStatusOptions: {
            single: 'Single',
            married: 'Married',
            divorced: 'Divorced',
            widowed: 'Widowed',
            separated: 'Separated'
        },
        educationalLevels: {
            noFormalEducation: 'No formal education',
            primaryEducation: 'Primary education',
            secondaryEducation: 'Secondary education',
            vocationalQualification: 'Vocational qualification',
            bachelorDegree: 'Bachelor degree',
            masterDegree: 'Master degree',
            doctorate: 'Doctorate or higher'
        },
        englishLevels: {
            basic: 'Basic',
            intermediate: 'Intermediate',
            advanced: 'Advanced',
            fluent: 'Fluent'
        },
        visaTypes: {
            No: "No",
            B2: "Visitor Visa (B-2)",
            B1: "Business Visa (B-1)",
            H1B: "Temporary Work Visa (H-1B)",
            F1: "Student Visa (F-1)",
            J1: "Exchange Visitor Visa (J-1)",
            K1: "Fiancé(e) Visa (K-1)",
            CR1: "Spouse Visa (CR-1/IR-1)",
            E2: "Investor Visa (E-2)",
            O1: "Artist/Academic Visa (O-1)",
            R1: "Religious Worker Visa (R-1)"
        },
        dsForm: {
            haveDirectRelativeInUS: "Do you have any direct relatives in the United States, excluding your parents?",
            relatives: "Detail who.",
            deniedVisa: "Have you ever been denied a visa?",
            canceledVisa: "Has your visa ever been canceled?",
            citizenRequest: "Have you ever applied for citizenship in the United States?",
            outOfCountryLastFiveYears: "Have you been out of the country in the last 5 years?",
            visitedCountries: "Which countries have you visited?",
            haveBeenOut: "Have you resided in another country for more than 6 months after turning 16?",
            triedEnterIllegallyToUs: "Have you ever tried to enter the United States illegally?"
        },
        contact: {
            companyName: "Company Name",
            companyDescription: "Company Description",
            contactName: "Contact Name",
            phoneNumber: "Phone Number",
            country: "Country",
            state: "State",
            city: "City",
            address: "Address",
            email: "Email",
            message: "Message",
            submit: "Submit"
        }
    },
    auth: {
        signUp: {
            email_label: 'Your email address',
            password_label: 'Create your password',
            email_input_placeholder: 'Your email address',
            password_input_placeholder: 'Your password',
            button_label: 'Sign up',
            loading_button_label: 'Signing up ...',
            link_text: "Don't have an account? Sign up",
            confirmation_text: "Check your email for the confirmation link"
        },
        signIn: {
            email_label: "Email address",
            password_label: "Your Password",
            email_input_placeholder: "Your email address",
            password_input_placeholder: "Your password",
            button_label: "Sign in",
            loading_button_label: "Signing in ...",
            social_provider_text: "Sign in with {{provider}}",
            link_text: "Already have an account? Sign in"
        },
        forgottenPassword: {
            email_label: "Email address",
            password_label: "Your Password",
            email_input_placeholder: "Your email address",
            button_label: "Send reset password instructions",
            loading_button_label: "Sending reset instructions ...",
            link_text: "Forgot your password?",
            confirmation_text: "Check your email for the password reset link"
        }
    },
    client: {
        setUpTitle: 'Before starting, we just need to fill out some information.',
        email: 'Email',
        organizationName: 'Organization Name',
        phoneNumber: 'Phone number'
    },
    whatWeDo: {
        findPeople: 'We find people that will help make your business a success.',
        deliverMatches: 'We deliver perfect',
        matches: 'matches',
        deliverTalent: 'We deliver the best talent in Latin America'
    },
    aboutUs: {
        whatWeDo: 'What we do?',
        description: `At LABORIA, we specialize in international recruitment, connecting qualified talent from Latin America with companies in the United States that need to fill temporary positions in sectors such as hospitality, construction, landscaping, and more. We stand out for offering a comprehensive service that includes recruitment, selection, and immigration procedures, ensuring regulatory compliance at every stage of the process.
Our commitment is to provide reliable and effective labor solutions, optimizing our clients' workforce while transforming the life opportunities of our collaborators. With a focus on excellence, integrity, and transparency, LABORIA not only facilitates legal processes but also builds bridges between opportunities and personal development, driving the success of all involved.`,
        howWeDoIt: 'How we do it?',
        howWeDoItDescription: `At LABORIA, we work under a comprehensive and professional approach that ensures effective results:`,
        howWeDoitList: {
            step1: `Search and selection: We identify and evaluate candidates who meet our clients' specific requirements, ensuring reliable and qualified talent.`,
            step2: 'Immigration management: We handle all necessary procedures, including H2A and H2B visas, ensuring legal compliance at every stage.',
            step3: 'Continuous support: We maintain constant communication with employers and workers, resolving doubts and offering support throughout the process.',
            step4: 'Transparency and reliability: We clearly inform the steps of the process, providing confidence to all involved.',
        },
        ourMision: 'Our Mission',
        mision: 'We connect companies in the United States with qualified talent from Latin America, offering comprehensive labor solutions in sectors such as hospitality, construction, gardening, and more. We transform lives through safe and ethical temporary job opportunities, ensuring regulatory compliance and excellence in service. We are the trusted bridge between companies and collaborators, promoting economic and professional growth for both.',
        ourVision: 'Our Vision',
        vision: 'To become the leader in international recruitment in Honduras, recognized for our excellence, innovation, and commitment to legal and ethical processes. In the medium term, expand our operations to other Latin American countries, positioning ourselves as the first choice for companies in the United States seeking qualified talent and for workers who want to build a better future through safe job opportunities. We aspire to transform the international labor market, promoting equal opportunities and strengthening communities on both sides of the border.'
    }
}

export default {
    translation
}