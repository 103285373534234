import { useTranslation } from "react-i18next";
import NavBar from "../../shared/components/nav-bar";
import LandingSection from "../../shared/components/landing-section";
import LandingHome from "./contact-us-home";
import LandingAbout from "./about";
import theme from "../../theme";
import Proccess from "./proccess";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { debounce } from "@mui/material";
import { useState } from "react";


export default function LandingPage() {
    const { t } = useTranslation();

    return (

        <NavBar showLinks={true} showIcon={true}> 
            <Outlet />
            <Footer />
        </NavBar>
    );
}