import React from 'react';
import LandingSection from '../../shared/components/landing-section';
import { useTranslation } from 'react-i18next';
import Proccess from './proccess';
import ClientAboutUs from './client/about-us';
import theme from '../../theme';
import WhatWeDo from './client/what-we-do';

const LandingHomeLayout: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <LandingSection
                id={"aboutUs"}
                style={{
                    width: '100%',
                    backgroundColor: theme.palette.primary.main
                }}>
                <ClientAboutUs />
            </LandingSection>
            <LandingSection
                id={t("nav.services")}
                style={{
                    width: '100%',
                    backgroundColor: 'white'
                }}>
                <WhatWeDo />
            </LandingSection>

            {/* <LandingSection
                id={t("nav.about")}
                height={1100}
                style={{
                    width: '100%'
                }}
            >
                <LandingAbout />
            </LandingSection> */}
            <LandingSection
                id={t("nav.steps")}
                style={{
                    width: '100%',
                    backgroundColor: "#fbf9e8"
                }}
            >
                <Proccess />
            </LandingSection>
        </>
    );
};

export default LandingHomeLayout;

